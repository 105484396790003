/* Header Start */
.header {
  background: #FFFFFF;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.08); }
  .header .nav-link {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #6A6A6A; }

/* Header End */
/* Footer Start */
.footer {
  background: #0A075F;
  padding-top: 40px;
  padding-bottom: 20px; }

/* Footer End */
/* Sidebar Start */
.sidebar {
  position: absolute;
  background: #fff;
  padding: 20px;
  right: 0;
  top: 0;
  width: 360px;
  height: 100%;
  z-index: 9999; }

/* Sidebar End */
/* Login Start */
.login {
  margin: 100px 0; }
  .login .bg-white {
    box-shadow: 0 4px 24px 4px rgba(36, 37, 101, 0.15); }
  .login .form-check-input:checked {
    background-color: #387EC1; }
  .login .form-check-input {
    width: 24px;
    height: 24px; }

/* Login End */
.news {
  background-image: url("../images/snow_3.png");
  background-repeat: no-repeat;
  background-position-y: 350px;
  padding-bottom: 100px; }
  .news-card_img {
    height: 285px;
    object-fit: cover;
    border-radius: 20px; }
    .news-card_img img {
      width: 100%;
      height: 100%; }
  .news-card_title {
    margin-top: 20px;
    font-weight: 700;
    font-size: 20px;
    color: #242565; }
  .news-card_text {
    margin-top: 20px;
    font-family: 'DM Sans', sans-serif;
    color: #000; }
  .news-card_time {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: #A5A5A5; }

.bg-snow {
  background-image: url("../images/snow_2.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom; }

.bg-snow-2 {
  background-image: url("../images/snow.png");
  background-repeat: no-repeat;
  background-position-y: 150px; }

.basket-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px; }

.login-btn {
  background: linear-gradient(90deg, #47A8DF 18.57%, #246BB5 55.49%, #387EC1 100%);
  border-radius: 8px;
  color: white; }

.custom-swiper-btn {
  background: #D9D9D9;
  border-radius: 12px;
  color: #969FA8;
  width: 60px;
  height: 60px;
  font-size: 24px; }
.custom-swiper-btn-mini {
  color: #969FA8;
  width: 32px !important;
  height: 32px !important;
  /*font-size: 24px;*/
  background: #F2F3F7;
  border-radius: 6px;
}

@media (max-width: 1024px) {
  .custom-swiper-btn {
    width: 40px;
    height: 40px;
    font-size: 20px; } }

 .custom-swiper-btn::after {
  display: none; }

.custom-dropdown {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.7);
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.custom-dropdown::after {
  display: none; }

.card-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 15px;
  width: 100%; }

.card-3d {
  padding: 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .card-3d-img img {
    position: absolute; }

.line-border {
  height: 1px;
  background-color: #FFFFFF;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BCC3D0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }

.calendar-swiper .swiper-button-prev.swiper-button-disabled,
.calendar-swiper .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  display: none; }

.calendar-swiper .swiper-button-next,
.calendar-swiper .swiper-button-prev {
  top: 65%;
  background: #F2F3F7;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .calendar-swiper .swiper-button-next:after,
  .calendar-swiper .swiper-button-prev:after {
    font-size: 14px;
    color: #000;
    font-weight: 900; }

.events-swiper .swiper-button-next,
.events-swiper .swiper-button-prev {
  right: 10px;
  left: auto;
  top: 15%;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  font-size: 10px; }

.events-swiper .swiper-button-next:after,
.events-swiper .swiper-button-prev:after {
  font-size: 14px;
  font-weight: 900; }

.events-swiper .swiper-button-prev {
  right: 40px;
  margin-right: 30px; }

.gallery2 .swiper-button-next,
.gallery2 .swiper-button-prev {
  top: 55%;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  font-size: 10px; }

.gallery2 .swiper-button-next:after,
.gallery2 .swiper-button-prev:after {
  font-size: 14px;
  font-weight: 900; }

.gallery2 .swiper-slide-thumb-active {
  opacity: 0.5; }

.bilet {
  background-image: url("../images/bilet.png");
  background-repeat: no-repeat;
  height: 240px;
  width: 100%;
  background-size: contain; }
  @media (max-width: 1199px) {
    .bilet {
      height: 175px; } }
  @media (max-width: 991px) {
    .bilet {
      height: 270px; } }
  @media (max-width: 767px) {
    .bilet {
      height: 200px; } }
  @media (max-width: 430px) {
    .bilet {
      height: 160px; } }
  @media (max-width: 320px) {
    .bilet {
      height: 140px; } }
  .bilet-shadow {
    background-image: url("../images/shadow.png");
    left: 20px;
    top: 30px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1; }

.popover {
  border: unset; }

.popover-body {
  background: #323242;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-family: 'DM Sans', sans-serif; }

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  border-bottom-color: #323242; }

.btn.disabled {
  background: #C4C4C4;
  color: #888888;
  height: 50px; }

.bilet2{
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px 20px;
}

.rem{
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 30px;
}